import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Card,Badge, NavBar, Space, Result } from 'antd-mobile'
import { 
  PhonebookOutline,
  UserAddOutline,
  UserContactOutline,
  TeamOutline,
  MessageOutline,
  UserCircleOutline,
  GlobalOutline,
} from 'antd-mobile-icons'
import { showConfirm } from '../../utils/util'
import api from '../../https/api';
import './index.scss'

export default function Index() {
  const {state} = useLocation()
  const navigate = useNavigate()
  //const {userid} = useParams()
  const [userid,setUserid] = useState(null)
  const [userinfo,setUserinfo] = useState({})
  const [signerList,setSignerList] = useState([])
  const [desc,setDesc] = useState('账户登录中...')
  const [total,setTotal] = useState({pending:0,sign:0,exam:0,reject:0,appeal:0})

  useEffect(()=>{
    const fetchData = async () => {
      if(state && state.userid > 0){
        const {data:{code,data,msg}} = await api.getUserinfo(state.userid)
        if(code === 0){
          setUserid(data.id)
          setUserinfo(data)
          setSignerList(data.signer_list)
          setTotal(data.total)
          localStorage.setItem('crm_client_userinfo',btoa(JSON.stringify(data)))
        }else{
          setDesc(msg)
        }
      }else{
        setDesc('请在企业微信内打开应用')
      }
    }
    fetchData()
  },[state])

  const right = (
    <div style={{ fontSize: 24 }}>
      <Space style={{ '--gap': '16px' }}>
        <UserCircleOutline onClick={() => logout()}/>
      </Space>
    </div>
  )

  const logout = async () => {
    const confirm = await showConfirm('确定要退出吗')
    if(!confirm) return
    localStorage.removeItem('crm_client_userinfo')
    setTimeout(() => {
      navigate(`/`)
    }, 500);
  }
  return (
    <div className='index'>
      <NavBar backArrow={false} right={right} className="crm-header">首页</NavBar>
      {
        userinfo.id ? (
          <>
            <Card title='业务中心' bodyClassName="crm-card" headerClassName="card-title">
              <Space wrap justify="start">
                  <div className='item' onClick={()=>{navigate(`/business/pending`)}}>
                    <Badge content={total.pending}>
                      <PhonebookOutline fontSize={36}/>
                      <div className='desc'>待跟进</div>
                    </Badge>
                  </div>
                  <div className='item' onClick={()=>{navigate(`/business/list`)}}>
                    <UserContactOutline fontSize={36}/>
                    <div className='desc'>我的进件</div>
                  </div>
                  <div className='item' onClick={()=>{navigate(`/business/add`)}}>
                    <UserAddOutline fontSize={36}/>
                    <div className='desc'>新增进件</div>
                  </div>
                  <div className='item' onClick={()=>{navigate('/business/reject')}}>
                    <div className='my-icon'><i className='iconfont icon-dingdanbohui'></i></div>
                    <div className='desc'>签约驳回</div>
                  </div>
                  <div className='item' onClick={()=>{navigate('/business/proof')}}>
                    <div className='my-icon'><i className='iconfont icon-yibaopingzheng'></i></div>
                    <div className='desc'>凭证驳回</div>
                  </div>
                  <div className='item' onClick={()=>{navigate(`/business/ysb`)}}>
                    <div className='my-icon'><i className='iconfont icon-xiaochengxu'></i></div>
                    <div className='desc'>意数宝</div>
                  </div>
                  {
                    userinfo.job.includes(3) ? (
                      <div className='item' onClick={()=>{navigate(`/business/service`)}}>
                        <Badge content={total.service}>
                          <div className='my-icon'><i className='iconfont icon-kefu'></i></div>
                          <div className='desc'>客服协助</div>
                        </Badge>
                      </div>
                    ) : null
                  }
                  {/* <div className='item' onClick={()=>{navigate(`/business/house`)}}>
                    <UserContactOutline fontSize={36}/>
                    <div className='desc'>房贷数据</div>
                  </div>
                  <div className='item' onClick={()=>{navigate(`/business/houseAdd`)}}>
                    <UserAddOutline fontSize={36}/>
                    <div className='desc'>房贷进件</div>
                  </div> */}
                  <div className='item' onClick={()=>{navigate(`/business/stat`)}}>
                    <div className='my-icon'><i className='iconfont icon-tongji1'></i></div>
                    <div className='desc'>业务统计</div>
                  </div>
              </Space>
            </Card>
            <Card title='房贷中心' bodyClassName="crm-card" headerClassName="card-title">
              <Space wrap justify="start">
                <div className='item' onClick={()=>{navigate(`/business/house`)}}>
                  {/* <UserContactOutline fontSize={36}/> */}
                  <div className='my-icon'><i className='iconfont icon-fangdai'></i></div>
                  <div className='desc'>房贷数据</div>
                </div>
                <div className='item' onClick={()=>{navigate(`/business/houseAdd`)}}>
                  <UserAddOutline fontSize={36}/>
                  <div className='desc'>房贷进件</div>
                </div>
                <div className='item' onClick={()=>{navigate(`/business/houseChannel`)}}>
                  <GlobalOutline fontSize={36}/>
                  <div className='desc'>房贷渠道</div>
                </div>
                <div className='item' onClick={()=>{navigate(`/business/houseChannelAdd`)}}>
                  <div><i className='iconfont icon-qudao_huaban'></i></div>
                  <div className='desc'>新增渠道</div>
                </div>
                {
                  userinfo.job.includes(5) ? (
                    <div className='item' onClick={()=>{navigate(`/business/houseSign`)}}>
                      <div className='my-icon'><i className='iconfont icon-guanjia'></i></div>
                      <div className='desc'>房贷管家</div>
                    </div>
                  ) : null
                }
              </Space>
            </Card>
            <Card title='考试中心' bodyClassName="crm-card" headerClassName="card-title">
              <Space wrap justify="start">
                <div className='item' onClick={()=>{navigate('/exam/center')}}>
                  <Badge content={total.exam}>
                    <div className='my-icon'><i className='iconfont icon-kaoshi'></i></div>
                    <div className='desc'>参加考试</div>
                  </Badge>
                </div>
                <div className='item' onClick={()=>{navigate('/exam/list')}}>
                  <div className='my-icon'><i className='iconfont icon-icon-kaoshijilu'></i></div>
                  <div className='desc'>考试回顾</div>
                </div>
              </Space>
            </Card>
            {
              userinfo && userinfo.leader ? (
                <Card title='团队数据' bodyClassName="crm-card" headerClassName="card-title">
                  <Space wrap justify="start">
                    <div className='item' onClick={()=>{navigate('/team/list')}}>
                      <TeamOutline fontSize={36}/>
                      <div className='desc'>分期业务</div>
                    </div>
                    <div className='item' onClick={()=>{navigate('/team/appeal')}}>
                      <Badge content={total.appeal}>
                        <MessageOutline fontSize={36}/>
                        <div className='desc'>反馈数据</div>
                      </Badge>
                    </div>
                    <div className='item' onClick={()=>{navigate('/team/ysb')}}>
                      <div className='my-icon'><i className='iconfont icon-xiaochengxu'></i></div>
                      <div className='desc'>意数宝</div>
                    </div>
                    <div className='item' onClick={()=>{navigate('/team/house')}}>
                      <div className='my-icon'><i className='iconfont icon-fangdai'></i></div>
                      <div className='desc'>房贷业务</div>
                    </div>
                    {
                      userinfo.job.includes(4) ? (
                        <>
                          <div className='item' onClick={()=>{navigate('/team/houseChannel')}}>
                            <div className='my-icon'><i className='iconfont icon-yuangongfenpei'></i></div>
                            <div className='desc'>房屋分配</div>
                          </div>
                        </>
                      ) : null
                    }
                  </Space>
                </Card>
              ) : null
            }
            {
              userinfo.job && (userinfo.job.includes(2))? (
                <Card title='签约中心' bodyClassName="crm-card" headerClassName="card-title">
                  <Space wrap justify="start">
                    {
                      userinfo.job.includes(2) ? (
                        <Space>
                          <div className='item' onClick={()=>navigate(`/sign/list/${userinfo.id}/1`)}>
                            <Badge content={total.sign}>
                              <div className='my-icon'><i className='iconfont icon-asset_application_for_collar'></i></div>
                              <div className='desc'>任务中心</div>
                            </Badge>
                          </div>
                          <div className='item' onClick={()=>navigate(`/sign/list/${userinfo.id}/2`)}>
                            <div className='my-icon'><i className='iconfont icon-renwuzhongxin'></i></div>
                            <div className='desc'>我的任务</div>
                          </div>
                        </Space>
                      ) : null
                    }
                    {
                      userid && parseInt(userid) === 29 ? (
                        <div className='item' onClick={()=>navigate(`/sign/reject`)}>
                          <Badge content={total.reject}>
                            <div className='my-icon'><i className='iconfont icon-jurassic_audit-something'></i></div>
                            <div className='desc'>驳回审批</div>
                          </Badge>
                        </div>
                      ) : null
                    }
                    {
                      signerList.map(item => {
                        return (
                          <div className='item' key={item.id} onClick={()=>navigate(`/sign/list/${item.id}/2`)}>
                            <div className='my-icon'>
                              <i className={[42,43].includes(item.id)? 'iconfont icon-nvshi' :'iconfont icon-nanshi'}></i>
                            </div>
                            <div className='desc'>{item.name}</div>
                          </div>
                        )
                      })
                    }
                  </Space>
                </Card>
              ) : null
            }
          </>
        ): (
          <Result
            status='warning'
            title='提示'
            description={desc}
          />
        ) 
      }
    </div>
  )
}
