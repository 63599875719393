import { lazy } from "react"
import { Navigate } from "react-router-dom"
import Login from "../pages/Login"
import Index from '../pages/Index'
import Business from "../pages/Business"
import Sign from '../pages/Sign'
import Exam from '../pages/Exam'
import Team from '../pages/Team'
import Outside from '../pages/Outside'
import Yishubao from '../pages/Yishubao'
import YishubaoHome from '../pages/Yishubao/Home'
import Activity from "../pages/Activity"
import Calculator from "../pages/Calculator"
import FAQ from "../pages/FAQ"
import HouseLoan from "../pages/HouseLoan"
import Contract from "../pages/Contract"
import Chattergee from "../pages/Chattergee"
import OA from "../pages/OA"
//import YishubaoExchange from '../pages/Yishubao/Exchange'

const BusinessAdd = lazy(()=>import('../pages/Business/Add'))
const BusinessList = lazy(()=>import('../pages/Business/List'))
const BusinessDetail = lazy(()=>import('../pages/Business/Detail'))
const BusinessReject = lazy(()=>import('../pages/Business/Reject'))
const BusinessService = lazy(()=>import('../pages/Business/Service'))
const BusinessServiceDetail = lazy(()=>import('../pages/Business/Service/Detail'))
const BusinessMoney = lazy(()=>import('../pages/Business/Money'))
const BusinessUpload = lazy(()=>import('../pages/Business/Upload'))
const BusinessUploadEdit = lazy(()=>import('../pages/Business/UploadEdit'))
const BusinessAppeal = lazy(()=>import('../pages/Business/Appeal'))
const BusinessProof = lazy(()=>import('../pages/Business/Proof'))
const BusinessPending = lazy(()=>import('../pages/Business/Pending'))
const PendingDetail = lazy(()=>import('../pages/Business/Pending/Detail'))
const PendingAdd = lazy(()=>import('../pages/Business/Pending/Add'))
const BusinessHouseAdd = lazy(()=>import('../pages/Business/House/Add'))
const BusinessHouseYsb = lazy(()=>import('../pages/Business/House/Ysb'))
const BusinessHouseList = lazy(()=>import('../pages/Business/House/List'))
const BusinessHouseDetail = lazy(()=>import('../pages/Business/House/Detail'))
const BusinessHouseUpload = lazy(()=>import('../pages/Business/House/Upload'))
const BusinessHouseChannel = lazy(()=>import('../pages/Business/House/Channel'))
const BusinessHouseSign = lazy(()=>import('../pages/Business/House/SignList'))
const BusinessHouseSignDetail = lazy(()=>import('../pages/Business/House/SignDetail'))
const BusinessHouseChannelAdd = lazy(()=>import('../pages/Business/House/ChannelAdd'))
const BusinessHouseChannelDetail = lazy(()=>import('../pages/Business/House/ChannelDetail'))
const BusinessYsb = lazy(()=>import('../pages/Business/Ysb/index'))
const BusinessYsbList = lazy(()=>import('../pages/Business/Ysb/List'))
const BusinessYsbDetail = lazy(()=>import('../pages/Business/Ysb/detail'))
const BusinessStat = lazy(()=>import('../pages/Business/Stat'))

const SignList = lazy(()=>import('../pages/Sign/List'))
const SignReject = lazy(()=>import('../pages/Sign/Reject'))
const SignUpdate = lazy(()=>import('../pages/Sign/Update'))
const SignHouse = lazy(()=>import('../pages/Sign/House'))

const ExamCenter = lazy(()=>import('../pages/Exam/Center'))
const ExamDetail = lazy(()=>import('../pages/Exam/Detail'))
const ExamList = lazy(()=>import('../pages/Exam/List'))
const ExamPaper = lazy(()=>import('../pages/Exam/Paper'))
const TeamList = lazy(()=>import('../pages/Team/List'))
const TeamAppeal = lazy(()=>import('../pages/Team/Appeal'))
const TeamYsb = lazy(()=>import('../pages/Team/Ysb'))
const TeamHouse = lazy(()=>import('../pages/Team/House'))
const TeamHouseChannel = lazy(()=>import('../pages/Team/HouseChannel'))

const OutsideRegister = lazy(()=>import('../pages/Outside/Register'))
//const OutsideIndex = lazy(()=>import('../pages/Outside/Index'))
const OutsideAdd = lazy(()=>import('../pages/Outside/Add'))
const OutsideList = lazy(()=>import('../pages/Outside/List'))
const OutsideIndex = lazy(()=>import('../pages/Outside/Index/index.jsx'))
const OutsideDetail = lazy(()=>import('../pages/Outside/Detail'))
const OutsidePartner = lazy(()=>import('../pages/Outside/Partner'))
const OutsideTeam = lazy(()=>import('../pages/Outside/Team'))
const OutsideProduct = lazy(()=>import('../pages/Outside/Product'))
const OutsideAccount = lazy(()=>import('../pages/Outside/Account'))
const OutsideChannel = lazy(()=>import('../pages/Outside/Channel'))
const OutsideWork = lazy(()=>import('../pages/Outside/Work'))
const OutsideLogin = lazy(()=>import('../pages/Outside/Login'))

const YishubaoIndex = lazy(()=>import('../pages/Yishubao/Index/index.jsx'))
const YishubaoMine = lazy(()=>import('../pages/Yishubao/Mine/index.jsx'))
const YishubaoExchange = lazy(()=>import('../pages/Yishubao/Exchange/index.jsx'))

const ActivityCarRegister = lazy(()=>import('../pages/Activity/Car/Register'))
const ActivityCarSuccess = lazy(()=>import('../pages/Activity/Car/Success'))
const ActivityTest = lazy(()=>import('../pages/Activity/Test'))
const LotteryLogin = lazy(()=>import('../pages/Activity/Lottery/Login'))
const LotteryPrize = lazy(()=>import('../pages/Activity/Lottery/Prize'))
const LotterySuccess = lazy(()=>import('../pages/Activity/Lottery/Success'))
const ICBCSigninLogin = lazy(()=>import('../pages/Activity/Signing/Login'))
const ICBCSigninRegister = lazy(()=>import('../pages/Activity/Signing/Register'))
const ICBCSigninSubscribe = lazy(()=>import('../pages/Activity/Signing/Subscribe'))
const ICBCSigninList = lazy(()=>import('../pages/Activity/Signing/List'))
const ICBCSigninSuccess = lazy(()=>import('../pages/Activity/Signing/Success'))

const FaqIndex = lazy(()=>import('../pages/FAQ/Index/index.jsx'))
const FaqLogin = lazy(()=>import('../pages/FAQ/Login'))
const FagList = (lazy(()=>import('../pages/FAQ/List')))
const FagDetail = (lazy(()=>import('../pages/FAQ/Detail')))

const HouseLoanLogin = lazy(()=>import('../pages/HouseLoan/Login'))
const HouseLoanHome = lazy(()=>import('../pages/HouseLoan/Home'))
const HouseLoanAdd = lazy(()=>import('../pages/HouseLoan/Add'))
const HouseLoanList = lazy(()=>import('../pages/HouseLoan/List'))
const HouseLoanDetail = lazy(()=>import('../pages/HouseLoan/Detail'))

const ChattergeeTool = (lazy(()=>import('../pages/Chattergee/Tool')))
const ChattergeeUser = (lazy(()=>import('../pages/Chattergee/User')))
const ChattergeeAuth = (lazy(()=>import('../pages/Chattergee/Auth')))
const ChattergeePurse = (lazy(()=>import('../pages/Chattergee/Purse')))
const ChattergeeSend = (lazy(()=>import('../pages/Chattergee/Send')))
const ChattergeeHome = (lazy(()=>import('../pages/Chattergee/Home')))
const ChattergeeList = (lazy(()=>import('../pages/Chattergee/Userlist/index.jsx')))
const ChattergeeAdd = (lazy(()=>import('../pages/Chattergee/Add')))


const OATest = (lazy(()=>import('../pages/OA/Test')))
const routes = [
  {
    path:'/',
    element:<Login/>
  },
  {
    path:'/login',
    element:<Login/>
  },
  {
    path:'/index',
    element:<Index/>
  },
  {
    path:'/business',
    element:<Business/>,
    children:[
      {
        path:'add',
        element:<BusinessAdd/>
      },
      {
        path:'list',
        element:<BusinessList/>
      },
      {
        path:'detail/:id',
        element:<BusinessDetail/>
      },
      {
        path:'reject',
        element:<BusinessReject/>
      },
      {
        path:'service',
        element:<BusinessService/>,
      },
      {
        path:'service_detail/:id',
        element:<BusinessServiceDetail/>,
      },
      {
        path:'money',
        element:<BusinessMoney/>
      },
      {
        path:'upload',
        element:<BusinessUpload/>
      },
      {
        path:'edit',
        element:<BusinessUploadEdit/>
      },
      {
        path:'appeal',
        element:<BusinessAppeal/>
      },
      {
        path:'proof',
        element:<BusinessProof/>,
      },
      {
        path:'pending',
        element:<BusinessPending/>,
      },
      {
        path:'pendingDetail/:id',
        element:<PendingDetail/>,
      },
      {
        path:'pendingAdd/:id',
        element:<PendingAdd/>,
      },
      {
        path:'house',
        element:<BusinessHouseList/>,
      },
      {
        path:'houseYsb/:id',
        element:<BusinessHouseYsb/>,
      },
      {
        path:'houseAdd',
        element:<BusinessHouseAdd/>,
      },
      {
        path:'houseDetail/:father/:id',
        element:<BusinessHouseDetail/>,
      },
      {
        path:'houseUpload/:id/:house_type',
        element:<BusinessHouseUpload/>,
      },
      {
        path:'houseSign',
        element:<BusinessHouseSign/>,
      },
      {
        path:'houseSignDetail/:id',
        element:<BusinessHouseSignDetail/>,
      },
      {
        path:'houseChannel',
        element:<BusinessHouseChannel/>,
      },
      {
        path:'houseChannelAdd',
        element:<BusinessHouseChannelAdd/>,
      },
      {
        path:'houseChannelDetail/:id',
        element:<BusinessHouseChannelDetail/>,
      },
      {
        path:'ysb',
        element:<BusinessYsb/>,
      },
      {
        path:'ysblist',
        element:<BusinessYsbList/>,
      },
      {
        path:'ysbdetail/:userid',
        element:<BusinessYsbDetail/>,
      },
      {
        path:'stat',
        element:<BusinessStat/>
      },
    ]
  },
  {
    path:'/team',
    element:<Team/>,
    children:[
      {
        path:'list',
        element:<TeamList/>
      },
      {
        path:'appeal',
        element:<TeamAppeal/>
      },
      {
        path:'ysb',
        element:<TeamYsb/>
      },
      {
        path:'house',
        element:<TeamHouse/>
      },
      {
        path:'houseChannel',
        element:<TeamHouseChannel/>
      }
    ]
  },
  {
    path:'/exam',
    element:<Exam/>,
    children:[
      {
        path:'center',
        element:<ExamCenter/>
      },
      {
        path:'detail/:id',
        element:<ExamDetail/>
      },
      {
        path:'list',
        element:<ExamList/>
      },
      {
        path:'paper/:id',
        element:<ExamPaper/>
      },
    ]
  },
  {
    path:'/sign',
    element:<Sign/>,
    children:[
      {
        path:'list/:userid/:flag',
        element:<SignList/>
      },
      {
        path:'reject',
        element:<SignReject/>
      },
      {
        path:'update/:id',
        element:<SignUpdate/>
      },
      {
        path:'house',
        element:<SignHouse/>
      }
    ]
  },
  {
    path:'/outside',
    element:<Outside/>,
    children:[
      {
        path:'',
        element:<Navigate to="index"/>
      },
      {
        path:'index/:openid',
        element:<OutsideIndex/>
      },
      {
        path:'register',
        element:<OutsideRegister/>
      },
      {
        path:'add',
        element:<OutsideAdd/>
      },
      {
        path:'list',
        element:<OutsideList/>
      },
      {
        path:'detail/:id',
        element:<OutsideDetail/>
      },
      {
        path:'partner',
        element:<OutsidePartner/>
      },
      {
        path:'team',
        element:<OutsideTeam/>
      },
      {
        path:'product',
        element:<OutsideProduct/>
      },
      {
        path:'account',
        element:<OutsideAccount/>
      },
      {
        path:'channel',
        element:<OutsideChannel/>
      },
      {
        path:'work',
        element:<OutsideWork/>
      },
      {
        path:'login',
        element:<OutsideLogin/>
      }
    ]
  },
  {
    path:'/faq',
    element:<FAQ/>,
    children:[
      {
        path:'',
        element:<Navigate to="login"/>
      },
      {
        path:'login',
        element:<FaqLogin/>
      },
      {
        path:'index',
        element:<FaqIndex/>
      },
      {
        path:'list',
        element:<FagList/>
      },
      {
        path:'detail/:id',
        element:<FagDetail/>
      }
    ]
  },
  {
    path:'contract',
    element:<Contract/>
  },
  {
    path:'houseloan',
    element:<HouseLoan/>,
    children:[
      {
        path:'login',
        element:<HouseLoanLogin/>
      },
      {
        path:'home/:openid',
        element:<HouseLoanHome/>
      },
      {
        path:'add',
        element:<HouseLoanAdd/>
      },
      {
        path:'list',
        element:<HouseLoanList/>
      },
      {
        path:'detail/:id',
        element:<HouseLoanDetail/>
      }
    ]
  },
  {
    path:'chattergee',
    element:<Chattergee/>,
    children:[
      {
        path:'',
        element:<Navigate to="home"/>
      },
      {
        path:'home',
        element:<ChattergeeHome/>
      },
      {
        path:'list/:id',
        element:<ChattergeeList/>
      },
      {
        path:'add/:id',
        element:<ChattergeeAdd/>
      },
      {
        path:'tool',
        element:<ChattergeeTool/>
      },
      {
        path:'user',
        element:<ChattergeeUser/>
      },
      {
        path:'auth/:id',
        element:<ChattergeeAuth/>
      },
      {
        path:'purse/:id',
        element:<ChattergeePurse/>
      },
      {
        path:'send/:external_userid',
        element:<ChattergeeSend/>
      },
    ]
    
  },
  {
    path:'/ysb',
    element:<Yishubao/>,
    children:[
      {
        path:'',
        element:<Navigate to="home"/>
      },
      // {
      //   path:'index',
      //   element:<YishubaoIndex />
      // },
      // {
      //   path:'mine',
      //   element:<YishubaoMine />
      // },
      {
        path:'home',
        element:<YishubaoHome/>,
        children:[
          {
            path:'',
            element:<Navigate to="index"/>
          },
          {
            path:'index',
            element:<YishubaoIndex />
          },
          {
            path:'mine',
            element:<YishubaoMine />
          },
        ]
      },
      {
        path:'exchange',
        element:<YishubaoExchange/>
      },
    ]
  },
  {
    path:'/activity',
    element:<Activity/>,
    children:[
      {
        path:'',
        element:<Navigate to="test"/>
      },
      {
        path:'test',
        element:<ActivityTest/>
      },
      {
        path:'car',
        children:[
          {
            path:'',
            element:<ActivityCarSuccess/>
          },
          {
            path:'register/:activity_id',
            element:<ActivityCarRegister/>
          },
          {
            path:'success',
            element:<ActivityCarSuccess/>
          }
        ]
      },
      {
        path:'lottery',
        children:[
          {
            path:'',
            element:<LotteryLogin/>
          },
          {
            path:'login',
            element:<LotteryLogin/>
          },
          {
            path:'Prize',
            element:<LotteryPrize/>
          },
          {
            path:'success',
            element:<LotterySuccess/>
          },
        ]
      },
      /* {
        path:'lotteryLogin',
        element:<LotteryLogin/>
      },
      {
        path:'lotteryPrize',
        element:<LotteryPrize/>
      },
      {
        path:'lotterySuccess',
        element:<LotterySuccess/>
      }, */
      {
        path:'signin',
        children:[
          {
            path:'',
            element:<ICBCSigninRegister/>
          },
          {
            path:'register',
            element:<ICBCSigninRegister/>
          },
          {
            path:'subscribe',
            element:<ICBCSigninSubscribe/>
          },
          {
            path:'login',
            element:<ICBCSigninLogin/>
          },
          {
            path:'list',
            element:<ICBCSigninList/>
          },
          {
            path:'success',
            element:<ICBCSigninSuccess/>
          },
        ]
      }
    ]
  },
  {
    path:'/calculator',
    element:<Calculator/>
  },
  {
    path:'/oa',
    element:<OA/>,
    children:[
      {
        path:'',
        element:<Navigate to="test"/>
      },
      {
        path:'test',
        element:<OATest/>
      },
    ]
  },
] 

export default routes