import React, { Suspense, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { NavBar,Result,Skeleton } from 'antd-mobile'

const ua = navigator.userAgent.toLowerCase()
export default function Outside() {
  const [title,setTitle] = useState('CRM')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  useEffect(() => {
    document.title = '沪融管家'
    if(pathname.includes('index')){
      setTitle('首页')
    }else if(pathname.includes('add')){
      setTitle('分期办理')
    }else if(pathname.includes('list')){
      setTitle('我的进件')
    }else if(pathname.includes('detail')){
      setTitle('进件详情')
    }else if(pathname.includes('register')){
      setTitle('注册')
    }else if(pathname.includes('partner')){
      setTitle('邀请伙伴')
    }else if(pathname.includes('team')){
      setTitle('我的团队')
    }else if(pathname.includes('product')){
      setTitle('产品中心')
    }else if(pathname.includes('account')){
      setTitle('我的账户')
    }else if(pathname.includes('channel')){
      setTitle('渠道管理')
    }else if(pathname.includes('work')){
      setTitle('管理中心')
    }else if(pathname.includes('login')){
      setTitle('登录')
    }
  },[pathname])
  return (
    <>
      {
        ua.includes('micromessenger') ? (
          <Suspense fallback={
              <>
                <Skeleton.Title animated />
                <Skeleton.Paragraph lineCount={5} animated />
              </>
            }
          >
            {
              !pathname.includes('index') ? (
                <NavBar 
                  className='crm-header' 
                  backArrow={!pathname.includes('login')} 
                  onBack={()=>navigate(-1)}
                >
                  {title}
                </NavBar>
              ) : null
            }
            <Outlet/>
          </Suspense>
        ) : (
          <Result
            status='warning'
            title='警告'
            description='请在微信内打开页面'
          />
        )
      }
    </>
  )
}