import React, { Suspense, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { NavBar,Skeleton } from 'antd-mobile'

export default function Team() {
  const [title,setTitle] = useState('CRM')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  useEffect(() => {
      if(pathname.includes('list')){
        setTitle('团队数据')
      }else if(pathname.includes('appeal')){
        setTitle('反馈数据')
      }else if(pathname.includes('ysb')){
        setTitle('意数宝')
      }else if(pathname.includes('houseChannel')){
        setTitle('房产交易数据')
      }else if(pathname.includes('house')){
        setTitle('房贷数据')
      }
  },[pathname])
  return (
    <Suspense fallback={
        <>
          <Skeleton.Title animated />
          <Skeleton.Paragraph lineCount={5} animated />
        </>
      }
    >
      <NavBar className='crm-header' onBack={()=>navigate(-1)}>{title}</NavBar>
      <Outlet/>
    </Suspense>
  )
}
