import React, { Suspense, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { NavBar,Result,Skeleton } from 'antd-mobile'

const ua = navigator.userAgent.toLowerCase()
export default function HouseLoan() {
  const [title,setTitle] = useState(null)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  useEffect(() => {
    document.title = '士翔房产经纪'
    if(pathname.includes('add')){
      setTitle('房产签约预约')
    }else if(pathname.includes('list')){
      setTitle('我的进件')
    }else if(pathname.includes('detail')){
      setTitle('进件详情')
    }else if(pathname.includes('login')){
      setTitle('登录')
    }
  },[pathname])
  return (
    <>
      {
        ua.includes('micromessenger') ? (
          <Suspense fallback={
              <>
                <Skeleton.Title animated />
                <Skeleton.Paragraph lineCount={5} animated />
              </>
            }
          >
            {
              !pathname.includes('home') ? (
                <NavBar 
                  className='crm-header' 
                  backArrow={ !pathname.includes('login')} 
                  onBack={()=>navigate(-1)}
                >
                  {title}
                </NavBar>
              ) : null
            }
            <Outlet/>
          </Suspense>

        ) : (
          <Result
            status='warning'
            title='警告'
            description='请在微信内打开页面'
          />
        )
      }
    </>
  )
}